import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="TJs blog posts"
          description="Personal website of TJ Kolleh a Sofware Engineer interested in machine learning, data visualizations, and backend development."
          keywords={[`tj`, `tinatuh`, `kolleh`, `blog`, `tkolleh`, `big data`, `data science`, `python machine learning`, `javascript`, `react`, `t.j.`, `julia computing`, `technology`, `machine learning`, `software`, `engineering`, `ml`, `ai`, `finance`, `healthcare`, `machine learning algorithms`,`ssh`,`sshfs`,`algorithms`,`computer vision`]}
          pagePath="/"
          schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Curious STEM Loving Fellow",
                "image" "https://curiousstemlovingfellow-blog-data.s3.amazonaws.com/profile-pic-edited.jpg"
              }
            }`
          }
        />
        <Bio />
        {posts.map(({ node }) => {
          const component = node.frontmatter.component
          if (component == "none"){
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div className={["row", "p-3"].join(' ')} key={node.fields.slug}>
              <h3 style={{ marginBottom: rhythm(1 / 4), }} >
                <Link style={{ boxShadow: `none` }} to={`/posts${node.fields.slug}`}>
                  {title}
                </Link>
              </h3>
              <div className={["text-wrap"].join(' ')}>
                <span><small>{node.frontmatter.date}</small></span>
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </div>
            </div>
          )} else {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div className={["row", "p-3"].join(' ')} key={node.fields.slug}>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={`/patient-flow-project/`}>
                    {title}
                  </Link>
                </h3>
                <div className={["text-wrap"].join(' ')}>
                  <span><small>{node.frontmatter.date}</small></span>
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
              </div>
            )}
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            component
          }
        }
      }
    }
  }
`
